import React from "react"
import SEO from "../components/seo"
import ogImageDatenschutz from "../images/og-image/og-image-datenschutz.png"

import Footer from "../components/footer"

export default function Datenschutz() {
  return (
    <>
      <SEO
        title="Datenschutz - Cuuno"
        description="Datenschutz spielt für Cuuno eine wichtige Rolle. Nähere Informationen beüglich des Datenschutzes finden Sie hier."
        image={ogImageDatenschutz}
        defaultTitle="Datenschutz - Cuuno"
        titleTemplate="Datenschutz "
      />
      <div className="cb-container">
     
            <div class="cb-header -info">
              <h1>
                Datenschutz <br />
              </h1>
            </div>

            <div class="cb-text">
              <p>
                Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
                Datenschutz hat einen besonders hohen Stellenwert für die
                Geschäftsleitung von Cuuno. Eine Nutzung der Internetseiten von
                Cuuno ist grundsätzlich ohne jede Angabe personenbezogener Daten
                möglich. Sofern eine betroffene Person besondere Services
                unseres Unternehmens über unsere Internetseite in Anspruch
                nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener
                Daten erforderlich werden. Ist die Verarbeitung
                personenbezogener Daten erforderlich und besteht für eine solche
                Verarbeitung keine gesetzliche Grundlage, holen wir generell
                eine Einwilligung der betroffenen Person ein. Die Verarbeitung
                personenbezogener Daten, beispielsweise des Namens, der
                Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen
                Person, erfolgt stets im Einklang mit der
                Datenschutz-Grundverordnung und in Übereinstimmung mit den für
                Cuuno geltenden landesspezifischen Datenschutzbestimmungen.
                Mittels dieser Datenschutzerklärung möchte unser Unternehmen die
                Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen,
                genutzten und verarbeiteten personenbezogenen Daten informieren.
                Ferner werden betroffene Personen mittels dieser
                Datenschutzerklärung über die ihnen zustehenden Rechte
                aufgeklärt. Cuuno hat als für die Verarbeitung Verantwortlicher
                zahlreiche technische und organisatorische Maßnahmen umgesetzt,
                um einen möglichst lückenlosen Schutz der über diese
                Internetseite verarbeiteten personenbezogenen Daten
                sicherzustellen. Dennoch können Internetbasierte
                Datenübertragungen grundsätzlich Sicherheitslücken aufweisen,
                sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus
                diesem Grund steht es jeder betroffenen Person frei,
                personenbezogene Daten auch auf alternativen Wegen,
                beispielsweise telefonisch, an uns zu übermitteln.
                {/* Section 1*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1> 1. Begriffsbestimmungen</h1>
                  <br />
                </div>
                Die Datenschutzerklärung von Cuuno beruht auf den
                Begrifflichkeiten, die durch den Europäischen Richtlinien- und
                Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
                (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll
                sowohl für die Öffentlichkeit als auch für unsere Kunden und
                Geschäftspartner einfach lesbar und verständlich sein. Um dies
                zu gewährleisten, möchten wir vorab die verwendeten
                Begrifflichkeiten erläutern. Wir verwenden in dieser
                Datenschutzerklärung unter anderem die folgenden Begriffe:
                <br /> <br />
                a) personenbezogene Daten Personenbezogene Daten sind alle
                Informationen, die sich auf eine identifizierte oder
                identifizierbare natürliche Person (im Folgenden „betroffene
                Person“) beziehen. Als identifizierbar wird eine natürliche
                Person angesehen, die direkt oder indirekt, insbesondere mittels
                Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
                zu Standortdaten, zu einer Online-Kennung oder zu einem oder
                mehreren besonderen Merkmalen, die Ausdruck der physischen,
                physiologischen, genetischen, psychischen, wirtschaftlichen,
                kulturellen oder sozialen Identität dieser natürlichen Person
                sind, identifiziert werden kann.
                <br /> <br />
                b) betroffene Person Betroffene Person ist jede identifizierte
                oder identifizierbare natürliche Person, deren personenbezogene
                Daten von dem für die Verarbeitung Verantwortlichen verarbeitet
                werden.
                <br /> <br />
                c) Verarbeitung Verarbeitung ist jeder mit oder ohne Hilfe
                automatisierter Verfahren ausgeführte Vorgang oder jede solche
                Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie
                das Erheben, das Erfassen, die Organisation, das Ordnen, die
                Speicherung, die Anpassung oder Veränderung, das Auslesen, das
                Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
                Verbreitung oder eine andere Form der Bereitstellung, den
                Abgleich oder die Verknüpfung, die Einschränkung, das Löschen
                oder die Vernichtung.
                <br /> <br />
                d) Einschränkung der Verarbeitung Einschränkung der Verarbeitung
                ist die Markierung gespeicherter personenbezogener Daten mit dem
                Ziel, ihre künftige Verarbeitung einzuschränken.
                <br /> <br />
                e) Profiling Profiling ist jede Art der automatisierten
                Verarbeitung personenbezogener Daten, die darin besteht, dass
                diese personenbezogenen Daten verwendet werden, um bestimmte
                persönliche Aspekte, die sich auf eine natürliche Person
                beziehen, zu bewerten, insbesondere, um Aspekte bezüglich
                Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher
                Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
                Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
                analysieren oder vorherzusagen.
                <br /> <br />
                f) Pseudonymisierung Pseudonymisierung ist die Verarbeitung
                personenbezogener Daten in einer Weise, auf welche die
                personenbezogenen Daten ohne Hinzuziehung zusätzlicher
                Informationen nicht mehr einer spezifischen betroffenen Person
                zugeordnet werden können, sofern diese zusätzlichen
                Informationen gesondert aufbewahrt werden und technischen und
                organisatorischen Maßnahmen unterliegen, die gewährleisten, dass
                die personenbezogenen Daten nicht einer identifizierten oder
                identifizierbaren natürlichen Person zugewiesen werden.
                <br /> <br />
                g) Verantwortlicher oder für die Verarbeitung Verantwortlicher
                Verantwortlicher oder für die Verarbeitung Verantwortlicher ist
                die natürliche oder juristische Person, Behörde, Einrichtung
                oder andere Stelle, die allein oder gemeinsam mit anderen über
                die Zwecke und Mittel der Verarbeitung von personenbezogenen
                Daten entscheidet. Sind die Zwecke und Mittel dieser
                Verarbeitung durch das Unionsrecht oder das Recht der
                Mitgliedstaaten vorgegeben, so kann der Verantwortliche
                beziehungsweise können die bestimmten Kriterien seiner Benennung
                nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
                vorgesehen werden.
                <br /> <br />
                h) Auftragsverarbeiter Auftragsverarbeiter ist eine natürliche
                oder juristische Person, Behörde, Einrichtung oder andere
                Stelle, die personenbezogene Daten im Auftrag des
                Verantwortlichen verarbeitet.
                <br /> <br />
                i) Empfänger Empfänger ist eine natürliche oder juristische
                Person, Behörde, Einrichtung oder andere Stelle, der
                personenbezogene Daten offengelegt werden, unabhängig davon, ob
                es sich bei ihr um einen Dritten handelt oder nicht. Behörden,
                die im Rahmen eines bestimmten Untersuchungsauftrags nach dem
                Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise
                personenbezogene Daten erhalten, gelten jedoch nicht als
                Empfänger.
                <br /> <br />
                j) Dritter Dritter ist eine natürliche oder juristische Person,
                Behörde, Einrichtung oder andere Stelle außer der betroffenen
                Person, dem Verantwortlichen, dem Auftragsverarbeiter und den
                Personen, die unter der unmittelbaren Verantwortung des
                Verantwortlichen oder des Auftragsverarbeiters befugt sind, die
                personenbezogenen Daten zu verarbeiten.
                <br /> <br />
                k) Einwilligung Einwilligung ist jede von der betroffenen Person
                freiwillig für den bestimmten Fall in informierter Weise und
                unmissverständlich abgegebene Willensbekundung in Form einer
                Erklärung oder einer sonstigen eindeutigen bestätigenden
                Handlung, mit der die betroffene Person zu verstehen gibt, dass
                sie mit der Verarbeitung der sie betreffenden personenbezogenen
                Daten einverstanden ist.
                {/* Section 2*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    {" "}
                    2. Name und Anschrift des für die Verarbeitung
                    Verantwortlichen
                  </h1>
                  <br />
                </div>
                Verantwortlicher im Sinne der Datenschutz-Grundverordnung,
                sonstiger in den Mitgliedstaaten der Europäischen Union
                geltenden Datenschutzgesetze und anderer Bestimmungen mit
                datenschutzrechtlichem Charakter ist die: Cuuno Digital Agency
                Johannes Schwegler und Oliver Hagel GbR Hopfenweg 12 88326
                Aulendorf Deutschland Tel.: 07525/7224 E-Mail:
                partnerships@cuuno.com Website: www.cuuno.com
                {/* Section 3*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1> 3. Cookies</h1>
                  <br />
                </div>
                Die Internetseiten von Cuuno verwenden Cookies. Cookies sind
                Textdateien, welche über einen Internetbrowser auf einem
                Computersystem abgelegt und gespeichert werden. Zahlreiche
                Internetseiten und Server verwenden Cookies. Viele Cookies
                enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine
                eindeutige Kennung des Cookies. Sie besteht aus einer
                Zeichenfolge, durch welche Internetseiten und Server dem
                konkreten Internetbrowser zugeordnet werden können, in dem das
                Cookie gespeichert wurde. Dies ermöglicht es den besuchten
                Internetseiten und Servern, den individuellen Browser der
                betroffenen Person von anderen Internetbrowsern, die andere
                Cookies enthalten, zu unterscheiden. Ein bestimmter
                Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt
                und identifiziert werden. Durch den Einsatz von Cookies kann
                Cuuno den Nutzern dieser Internetseite nutzerfreundlichere
                Services bereitstellen, die ohne die Cookie-Setzung nicht
                möglich wären. Mittels eines Cookies können die Informationen
                und Angebote auf unserer Internetseite im Sinne des Benutzers
                optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt,
                die Benutzer unserer Internetseite wiederzuerkennen. Zweck
                dieser Wiedererkennung ist es, den Nutzern die Verwendung
                unserer Internetseite zu erleichtern. Der Benutzer einer
                Internetseite, die Cookies verwendet, muss beispielsweise nicht
                bei jedem Besuch der Internetseite erneut seine Zugangsdaten
                eingeben, weil dies von der Internetseite und dem auf dem
                Computersystem des Benutzers abgelegten Cookie übernommen wird.
                Ein weiteres Beispiel ist das Cookie eines Warenkorbes im
                Online-Shop. Der Online-Shop merkt sich die Artikel, die ein
                Kunde in den virtuellen Warenkorb gelegt hat, über ein Cookie.
                Die betroffene Person kann die Setzung von Cookies durch unsere
                Internetseite jederzeit mittels einer entsprechenden Einstellung
                des genutzten Internetbrowsers verhindern und damit der Setzung
                von Cookies dauerhaft widersprechen. Ferner können bereits
                gesetzte Cookies jederzeit über einen Internetbrowser oder
                andere Softwareprogramme gelöscht werden. Dies ist in allen
                gängigen Internetbrowsern möglich. Deaktiviert die betroffene
                Person die Setzung von Cookies in dem genutzten Internetbrowser,
                sind unter Umständen nicht alle Funktionen unserer Internetseite
                vollumfänglich nutzbar.
                {/* Section 4*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>4. Erfassung von allgemeinen Daten und Informationen</h1>
                  <br />
                </div>
                Die Internetseite der Cuuno erfasst mit jedem Aufruf der
                Internetseite durch eine betroffene Person oder ein
                automatisiertes System eine Reihe von allgemeinen Daten und
                Informationen. Diese allgemeinen Daten und Informationen werden
                in den Logfiles des Servers gespeichert. Erfasst werden können
                die (1) verwendeten Browsertypen und Versionen, (2) das vom
                zugreifenden System verwendete Betriebssystem, (3) die
                Internetseite, von welcher ein zugreifendes System auf unsere
                Internetseite gelangt (sogenannte Referrer), (4) die
                Unterwebseiten, welche über ein zugreifendes System auf unserer
                Internetseite angesteuert werden, (5) das Datum und die Uhrzeit
                eines Zugriffs auf die Internetseite, (6) eine
                Internet-Protokoll-Adresse (IP-Adresse), (7) der
                Internet-Service-Provider des zugreifenden Systems und (8)
                sonstige ähnliche Daten und Informationen, die der
                Gefahrenabwehr im Falle von Angriffen auf unsere
                informationstechnologischen Systeme dienen. Bei der Nutzung
                dieser allgemeinen Daten und Informationen zieht Cuuno keine
                Rückschlüsse auf die betroffene Person. Diese Informationen
                werden vielmehr benötigt, um (1) die Inhalte unserer
                Internetseite korrekt auszuliefern, (2) die Inhalte unserer
                Internetseite sowie die Werbung für diese zu optimieren, (3) die
                dauerhafte Funktionsfähigkeit unserer
                informationstechnologischen Systeme und der Technik unserer
                Internetseite zu gewährleisten sowie (4) um
                Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
                Strafverfolgung notwendigen Informationen bereitzustellen. Diese
                anonym erhobenen Daten und Informationen werden durch Cuuno
                daher einerseits statistisch und ferner mit dem Ziel
                ausgewertet, den Datenschutz und die Datensicherheit in unserem
                Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau
                für die von uns verarbeiteten personenbezogenen Daten
                sicherzustellen. Die anonymen Daten der Server-Logfiles werden
                getrennt von allen durch eine betroffene Person angegebenen
                personenbezogenen Daten gespeichert.
                {/* Section 5*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>5. Kontaktmöglichkeit über die Internetseite</h1>
                  <br />
                </div>
                Die Internetseite von Cuuno enthält aufgrund von gesetzlichen
                Vorschriften Angaben, die eine schnelle elektronische
                Kontaktaufnahme zu unserem Unternehmen sowie eine unmittelbare
                Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine
                Adresse der sogenannten elektronischen Post (E-Mail-Adresse)
                umfasst. Sofern eine betroffene Person per E-Mail oder über ein
                Kontaktformular den Kontakt mit dem für die Verarbeitung
                Verantwortlichen aufnimmt, werden die von der betroffenen Person
                übermittelten personenbezogenen Daten automatisch gespeichert.
                Solche auf freiwilliger Basis von einer betroffenen Person an
                den für die Verarbeitung Verantwortlichen übermittelten
                personenbezogenen Daten werden für Zwecke der Bearbeitung oder
                der Kontaktaufnahme zur betroffenen Person gespeichert. Es
                erfolgt keine Weitergabe dieser personenbezogenen Daten an
                Dritte.
                {/* Section 6*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    6. Routinemäßige Löschung und Sperrung von personenbezogenen
                    Daten
                  </h1>
                  <br />
                </div>
                Der für die Verarbeitung Verantwortliche verarbeitet und
                speichert personenbezogene Daten der betroffenen Person nur für
                den Zeitraum, der zur Erreichung des Speicherungszwecks
                erforderlich ist oder sofern dies durch den Europäischen
                Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber
                in Gesetzen oder Vorschriften, welchen der für die Verarbeitung
                Verantwortliche unterliegt, vorgesehen wurde. Entfällt der
                Speicherungszweck oder läuft eine vom Europäischen Richtlinien-
                und Verordnungsgeber oder einem anderen zuständigen Gesetzgeber
                vorgeschriebene Speicherfrist ab, werden die personenbezogenen
                Daten routinemäßig und entsprechend den gesetzlichen
                Vorschriften gesperrt oder gelöscht.
                {/* Section 7*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>7. Rechte der betroffenen Person</h1>
                  <br />
                </div>
                a) Recht auf Bestätigung Jede betroffene Person hat das vom
                Europäischen Richtlinien- und Verordnungsgeber eingeräumte
                Recht, von dem für die Verarbeitung Verantwortlichen eine
                Bestätigung darüber zu verlangen, ob sie betreffende
                personenbezogene Daten verarbeitet werden. Möchte eine
                betroffene Person dieses Bestätigungsrecht in Anspruch nehmen,
                kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
                Verarbeitung Verantwortlichen wenden. b) Recht auf Auskunft Jede
                von der Verarbeitung personenbezogener Daten betroffene Person
                hat das vom Europäischen Richtlinien- und Verordnungsgeber
                gewährte Recht, jederzeit von dem für die Verarbeitung
                Verantwortlichen unentgeltliche Auskunft über die zu seiner
                Person gespeicherten personenbezogenen Daten und eine Kopie
                dieser Auskunft zu erhalten. Ferner hat der Europäische
                Richtlinien- und Verordnungsgeber der betroffenen Person
                Auskunft über folgende Informationen zugestanden: die
                Verarbeitungszwecke die Kategorien personenbezogener Daten, die
                verarbeitet werden die Empfänger oder Kategorien von Empfängern,
                gegenüber denen die personenbezogenen Daten offengelegt worden
                sind oder noch offengelegt werden, insbesondere bei Empfängern
                in Drittländern oder bei internationalen Organisationen falls
                möglich die geplante Dauer, für die die personenbezogenen Daten
                gespeichert werden, oder, falls dies nicht möglich ist, die
                Kriterien für die Festlegung dieser Dauer das Bestehen eines
                Rechts auf Berichtigung oder Löschung der sie betreffenden
                personenbezogenen Daten oder auf Einschränkung der Verarbeitung
                durch den Verantwortlichen oder eines Widerspruchsrechts gegen
                diese Verarbeitung das Bestehen eines Beschwerderechts bei einer
                Aufsichtsbehörde wenn die personenbezogenen Daten nicht bei der
                betroffenen Person erhoben werden: Alle verfügbaren
                Informationen über die Herkunft der Daten das Bestehen einer
                automatisierten Entscheidungsfindung einschließlich Profiling
                gemäß Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen
                Fällen — aussagekräftige Informationen über die involvierte
                Logik sowie die Tragweite und die angestrebten Auswirkungen
                einer derartigen Verarbeitung für die betroffene Person Ferner
                steht der betroffenen Person ein Auskunftsrecht darüber zu, ob
                personenbezogene Daten an ein Drittland oder an eine
                internationale Organisation übermittelt wurden. Sofern dies der
                Fall ist, so steht der betroffenen Person im Übrigen das Recht
                zu, Auskunft über die geeigneten Garantien im Zusammenhang mit
                der Übermittlung zu erhalten. Möchte eine betroffene Person
                dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu
                jederzeit an einen Mitarbeiter des für die Verarbeitung
                Verantwortlichen wenden. c) Recht auf Berichtigung Jede von der
                Verarbeitung personenbezogener Daten betroffene Person hat das
                vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                Recht, die unverzügliche Berichtigung sie betreffender
                unrichtiger personenbezogener Daten zu verlangen. Ferner steht
                der betroffenen Person das Recht zu, unter Berücksichtigung der
                Zwecke der Verarbeitung, die Vervollständigung unvollständiger
                personenbezogener Daten — auch mittels einer ergänzenden
                Erklärung — zu verlangen. Möchte eine betroffene Person dieses
                Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu
                jederzeit an einen Mitarbeiter des für die Verarbeitung
                Verantwortlichen wenden. d) Recht auf Löschung (Recht auf
                Vergessen werden) Jede von der Verarbeitung personenbezogener
                Daten betroffene Person hat das vom Europäischen Richtlinien-
                und Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu
                verlangen, dass die sie betreffenden personenbezogenen Daten
                unverzüglich gelöscht werden, sofern einer der folgenden Gründe
                zutrifft und soweit die Verarbeitung nicht erforderlich ist: Die
                personenbezogenen Daten wurden für solche Zwecke erhoben oder
                auf sonstige Weise verarbeitet, für welche sie nicht mehr
                notwendig sind. Die betroffene Person widerruft ihre
                Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1
                Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO
                stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für
                die Verarbeitung. Die betroffene Person legt gemäß Art. 21 Abs.
                1 DS-GVO Widerspruch gegen die Verarbeitung ein, und es liegen
                keine vorrangigen berechtigten Gründe für die Verarbeitung vor,
                oder die betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO
                Widerspruch gegen die Verarbeitung ein. Die personenbezogenen
                Daten wurden unrechtmäßig verarbeitet. Die Löschung der
                personenbezogenen Daten ist zur Erfüllung einer rechtlichen
                Verpflichtung nach dem Unionsrecht oder dem Recht der
                Mitgliedstaaten erforderlich, dem der Verantwortliche
                unterliegt. Die personenbezogenen Daten wurden in Bezug auf
                angebotene Dienste der Informationsgesellschaft gemäß Art. 8
                Abs. 1 DS-GVO erhoben. Sofern einer der oben genannten Gründe
                zutrifft und eine betroffene Person die Löschung von
                personenbezogenen Daten, die bei Cuuno gespeichert sind,
                veranlassen möchte, kann sie sich hierzu jederzeit an einen
                Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                Der Mitarbeiter von Cuuno wird veranlassen, dass dem
                Löschverlangen unverzüglich nachgekommen wird. Wurden die
                personenbezogenen Daten von Cuuno öffentlich gemacht und ist
                unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1
                DS-GVO zur Löschung der personenbezogenen Daten verpflichtet, so
                trifft die Cuuno unter Berücksichtigung der verfügbaren
                Technologie und der Implementierungskosten angemessene
                Maßnahmen, auch technischer Art, um andere für die
                Datenverarbeitung Verantwortliche, welche die veröffentlichten
                personenbezogenen Daten verarbeiten, darüber in Kenntnis zu
                setzen, dass die betroffene Person von diesen anderen für die
                Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links
                zu diesen personenbezogenen Daten oder von Kopien oder
                Replikationen dieser personenbezogenen Daten verlangt hat,
                soweit die Verarbeitung nicht erforderlich ist. Der Mitarbeiter
                von Cuuno wird im Einzelfall das Notwendige veranlassen. e)
                Recht auf Einschränkung der Verarbeitung Jede von der
                Verarbeitung personenbezogener Daten betroffene Person hat das
                vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                Recht, von dem Verantwortlichen die Einschränkung der
                Verarbeitung zu verlangen, wenn eine der folgenden
                Voraussetzungen gegeben ist: Die Richtigkeit der
                personenbezogenen Daten wird von der betroffenen Person
                bestritten, und zwar für eine Dauer, die es dem Verantwortlichen
                ermöglicht, die Richtigkeit der personenbezogenen Daten zu
                überprüfen. Die Verarbeitung ist unrechtmäßig, die betroffene
                Person lehnt die Löschung der personenbezogenen Daten ab und
                verlangt stattdessen die Einschränkung der Nutzung der
                personenbezogenen Daten. Der Verantwortliche benötigt die
                personenbezogenen Daten für die Zwecke der Verarbeitung nicht
                länger, die betroffene Person benötigt sie jedoch zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                Die betroffene Person hat Widerspruch gegen die Verarbeitung
                gem. Art. 21 Abs. 1 DS-GVO eingelegt und es steht noch nicht
                fest, ob die berechtigten Gründe des Verantwortlichen gegenüber
                denen der betroffenen Person überwiegen. Sofern eine der oben
                genannten Voraussetzungen gegeben ist und eine betroffene Person
                die Einschränkung von personenbezogenen Daten, die bei Cuuno
                gespeichert sind, verlangen möchte, kann sie sich hierzu
                jederzeit an einen Mitarbeiter des für die Verarbeitung
                Verantwortlichen wenden. Der Mitarbeiter von Cuuno wird die
                Einschränkung der Verarbeitung veranlassen. f) Recht auf
                Datenübertragbarkeit Jede von der Verarbeitung personenbezogener
                Daten betroffene Person hat das vom Europäischen Richtlinien-
                und Verordnungsgeber gewährte Recht, die sie betreffenden
                personenbezogenen Daten, welche durch die betroffene Person
                einem Verantwortlichen bereitgestellt wurden, in einem
                strukturierten, gängigen und maschinenlesbaren Format zu
                erhalten. Sie hat außerdem das Recht, diese Daten einem anderen
                Verantwortlichen ohne Behinderung durch den Verantwortlichen,
                dem die personenbezogenen Daten bereitgestellt wurden, zu
                übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß
                Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a
                DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b
                DS-GVO beruht und die Verarbeitung mithilfe automatisierter
                Verfahren erfolgt, sofern die Verarbeitung nicht für die
                Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
                Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt,
                welche dem Verantwortlichen übertragen wurde. Ferner hat die
                betroffene Person bei der Ausübung ihres Rechts auf
                Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu
                erwirken, dass die personenbezogenen Daten direkt von einem
                Verantwortlichen an einen anderen Verantwortlichen übermittelt
                werden, soweit dies technisch machbar ist und sofern hiervon
                nicht die Rechte und Freiheiten anderer Personen beeinträchtigt
                werden. Zur Geltendmachung des Rechts auf Datenübertragbarkeit
                kann sich die betroffene Person jederzeit an einen Mitarbeiter
                von Cuuno wenden. g) Recht auf Widerspruch Jede von der
                Verarbeitung personenbezogener Daten betroffene Person hat das
                vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                Recht, aus Gründen, die sich aus ihrer besonderen Situation
                ergeben, jederzeit gegen die Verarbeitung sie betreffender
                personenbezogener Daten, die aufgrund von Art. 6 Abs. 1
                Buchstaben e oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies
                gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
                Cuuno verarbeitet die personenbezogenen Daten im Falle des
                Widerspruchs nicht mehr, es sei denn, wir können zwingende
                schutzwürdige Gründe für die Verarbeitung nachweisen, die den
                Interessen, Rechten und Freiheiten der betroffenen Person
                überwiegen, oder die Verarbeitung dient der Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen. Verarbeitet
                Cuuno personenbezogene Daten, um Direktwerbung zu betreiben, so
                hat die betroffene Person das Recht, jederzeit Widerspruch gegen
                die Verarbeitung der personenbezogenen Daten zum Zwecke
                derartiger Werbung einzulegen. Dies gilt auch für das Profiling,
                soweit es mit solcher Direktwerbung in Verbindung steht.
                Widerspricht die betroffene Person gegenüber von Cuuno der
                Verarbeitung für Zwecke der Direktwerbung, so wird Cuuno die
                personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.
                Zudem hat die betroffene Person das Recht, aus Gründen, die sich
                aus ihrer besonderen Situation ergeben, gegen die sie
                betreffende Verarbeitung personenbezogener Daten, die bei Cuuno
                zu wissenschaftlichen oder historischen Forschungszwecken oder
                zu statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen,
                Widerspruch einzulegen, es sei denn, eine solche Verarbeitung
                ist zur Erfüllung einer im öffentlichen Interesse liegenden
                Aufgabe erforderlich. Zur Ausübung des Rechts auf Widerspruch
                kann sich die betroffene Person direkt jeden Mitarbeiter von
                Cuuno oder einen anderen Mitarbeiter wenden. Der betroffenen
                Person steht es ferner frei, im Zusammenhang mit der Nutzung von
                Diensten der Informationsgesellschaft, ungeachtet der Richtlinie
                2002/58/EG, ihr Widerspruchsrecht mittels automatisierter
                Verfahren auszuüben, bei denen technische Spezifikationen
                verwendet werden. h) Automatisierte Entscheidungen im Einzelfall
                einschließlich Profiling Jede von der Verarbeitung
                personenbezogener Daten betroffene Person hat das vom
                Europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
                nicht einer ausschließlich auf einer automatisierten
                Verarbeitung — einschließlich Profiling — beruhenden
                Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche
                Wirkung entfaltet oder sie in ähnlicher Weise erheblich
                beeinträchtigt, sofern die Entscheidung (1) nicht für den
                Abschluss oder die Erfüllung eines Vertrags zwischen der
                betroffenen Person und dem Verantwortlichen erforderlich ist,
                oder (2) aufgrund von Rechtsvorschriften der Union oder der
                Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig
                ist und diese Rechtsvorschriften angemessene Maßnahmen zur
                Wahrung der Rechte und Freiheiten sowie der berechtigten
                Interessen der betroffenen Person enthalten oder (3) mit
                ausdrücklicher Einwilligung der betroffenen Person erfolgt. Ist
                die Entscheidung (1) für den Abschluss oder die Erfüllung eines
                Vertrags zwischen der betroffenen Person und dem
                Verantwortlichen erforderlich oder (2) erfolgt sie mit
                ausdrücklicher Einwilligung der betroffenen Person, trifft Cuuno
                angemessene Maßnahmen, um die Rechte und Freiheiten sowie die
                berechtigten Interessen der betroffenen Person zu wahren, wozu
                mindestens das Recht auf Erwirkung des Eingreifens einer Person
                seitens des Verantwortlichen, auf Darlegung des eigenen
                Standpunkts und auf Anfechtung der Entscheidung gehört. Möchte
                die betroffene Person Rechte mit Bezug auf automatisierte
                Entscheidungen geltend machen, kann sie sich hierzu jederzeit an
                einen Mitarbeiter des für die Verarbeitung Verantwortlichen
                wenden. i) Recht auf Widerruf einer datenschutzrechtlichen
                Einwilligung Jede von der Verarbeitung personenbezogener Daten
                betroffene Person hat das vom Europäischen Richtlinien- und
                Verordnungsgeber gewährte Recht, eine Einwilligung zur
                Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
                Möchte die betroffene Person ihr Recht auf Widerruf einer
                Einwilligung geltend machen, kann sie sich hierzu jederzeit an
                einen Mitarbeiter des für die Verarbeitung Verantwortlichen
                wenden.
                {/* Section 8*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    8. Datenschutz bei Bewerbungen und im Bewerbungsverfahren
                  </h1>
                  <br />
                </div>
                Der für die Verarbeitung Verantwortliche erhebt und verarbeitet
                die personenbezogenen Daten von Bewerbern zum Zwecke der
                Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch
                auf elektronischem Wege erfolgen. Dies ist insbesondere dann der
                Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf
                dem elektronischen Wege, beispielsweise per E-Mail oder über ein
                auf der Internetseite befindliches Webformular, an den für die
                Verarbeitung Verantwortlichen übermittelt. Schließt der für die
                Verarbeitung Verantwortliche einen Anstellungsvertrag mit einem
                Bewerber, werden die übermittelten Daten zum Zwecke der
                Abwicklung des Beschäftigungsverhältnisses unter Beachtung der
                gesetzlichen Vorschriften gespeichert. Wird von dem für die
                Verarbeitung Verantwortlichen kein Anstellungsvertrag mit dem
                Bewerber geschlossen, so werden die Bewerbungsunterlagen zwei
                Monate nach Bekanntgabe der Absageentscheidung automatisch
                gelöscht, sofern einer Löschung keine sonstigen berechtigten
                Interessen des für die Verarbeitung Verantwortlichen
                entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne
                ist beispielsweise eine Beweispflicht in einem Verfahren nach
                dem Allgemeinen Gleichbehandlungsgesetz (AGG).
                {/* Section 9*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    9. Datenschutzbestimmungen zu Einsatz und Verwendung von
                    Facebook
                  </h1>
                  <br />
                </div>
                Der für die Verarbeitung Verantwortliche hat auf dieser
                Internetseite Komponenten des Unternehmens Facebook integriert.
                Facebook ist ein soziales Netzwerk. Ein soziales Netzwerk ist
                ein im Internet betriebener sozialer Treffpunkt, eine
                Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht,
                untereinander zu kommunizieren und im virtuellen Raum zu
                interagieren. Ein soziales Netzwerk kann als Plattform zum
                Austausch von Meinungen und Erfahrungen dienen oder ermöglicht
                es der Internetgemeinschaft, persönliche oder
                unternehmensbezogene Informationen bereitzustellen. Facebook
                ermöglicht den Nutzern des sozialen Netzwerkes unter anderem die
                Erstellung von privaten Profilen, den Upload von Fotos und eine
                Vernetzung über Freundschaftsanfragen. Betreibergesellschaft von
                Facebook ist die Facebook, Inc., 1 Hacker Way, Menlo Park, CA
                94025, USA. Für die Verarbeitung personenbezogener Daten
                Verantwortlicher ist, wenn eine betroffene Person außerhalb der
                USA oder Kanada lebt, die Facebook Ireland Ltd., 4 Grand Canal
                Square, Grand Canal Harbour, Dublin 2, Ireland. Durch jeden
                Aufruf einer der Einzelseiten dieser Internetseite, die durch
                den für die Verarbeitung Verantwortlichen betrieben wird und auf
                welcher eine Facebook-Komponente (Facebook-Plug-In) integriert
                wurde, wird der Internetbrowser auf dem
                informationstechnologischen System der betroffenen Person
                automatisch durch die jeweilige Facebook-Komponente veranlasst,
                eine Darstellung der entsprechenden Facebook-Komponente von
                Facebook herunterzuladen. Eine Gesamtübersicht über alle
                Facebook-Plug-Ins kann unter
                https://developers.facebook.com/docs/plugins/?locale=de_DE
                abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält
                Facebook Kenntnis darüber, welche konkrete Unterseite unserer
                Internetseite durch die betroffene Person besucht wird. Sofern
                die betroffene Person gleichzeitig bei Facebook eingeloggt ist,
                erkennt Facebook mit jedem Aufruf unserer Internetseite durch
                die betroffene Person und während der gesamten Dauer des
                jeweiligen Aufenthaltes auf unserer Internetseite, welche
                konkrete Unterseite unserer Internetseite die betroffene Person
                besucht. Diese Informationen werden durch die
                Facebook-Komponente gesammelt und durch Facebook dem jeweiligen
                Facebook-Account der betroffenen Person zugeordnet. Betätigt die
                betroffene Person einen der auf unserer Internetseite
                integrierten Facebook-Buttons, beispielsweise den „Gefällt
                mir“-Button, oder gibt die betroffene Person einen Kommentar ab,
                ordnet Facebook diese Information dem persönlichen
                Facebook-Benutzerkonto der betroffenen Person zu und speichert
                diese personenbezogenen Daten. Facebook erhält über die
                Facebook-Komponente immer dann eine Information darüber, dass
                die betroffene Person unsere Internetseite besucht hat, wenn die
                betroffene Person zum Zeitpunkt des Aufrufs unserer
                Internetseite gleichzeitig bei Facebook eingeloggt ist; dies
                findet unabhängig davon statt, ob die betroffene Person die
                Facebook-Komponente anklickt oder nicht. Ist eine derartige
                Übermittlung dieser Informationen an Facebook von der
                betroffenen Person nicht gewollt, kann diese die Übermittlung
                dadurch verhindern, dass sie sich vor einem Aufruf unserer
                Internetseite aus ihrem Facebook-Account ausloggt. Die von
                Facebook veröffentlichte Datenrichtlinie, die unter
                https://de-de.facebook.com/about/privacy/ abrufbar ist, gibt
                Aufschluss über die Erhebung, Verarbeitung und Nutzung
                personenbezogener Daten durch Facebook. Ferner wird dort
                erläutert, welche Einstellungsmöglichkeiten Facebook zum Schutz
                der Privatsphäre der betroffenen Person bietet. Zudem sind
                unterschiedliche Applikationen erhältlich, die es ermöglichen,
                eine Datenübermittlung an Facebook zu unterdrücken. Solche
                Applikationen können durch die betroffene Person genutzt werden,
                um eine Datenübermittlung an Facebook zu unterdrücken.
                {/* Section 10*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    10. Datenschutzbestimmungen zu Einsatz und Verwendung von
                    Google Analytics (mit Anonymisierungsfunktion)
                  </h1>
                  <br />
                </div>
                Der für die Verarbeitung Verantwortliche hat auf dieser
                Internetseite die Komponente Google Analytics (mit
                Anonymisierungsfunktion) integriert. Google Analytics ist ein
                Web-Analyse-Dienst. Web-Analyse ist die Erhebung, Sammlung und
                Auswertung von Daten über das Verhalten von Besuchern von
                Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem
                Daten darüber, von welcher Internetseite eine betroffene Person
                auf eine Internetseite gekommen ist (sogenannte Referrer), auf
                welche Unterseiten der Internetseite zugegriffen oder wie oft
                und für welche Verweildauer eine Unterseite betrachtet wurde.
                Eine Web-Analyse wird überwiegend zur Optimierung einer
                Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung
                eingesetzt. Betreibergesellschaft der
                Google-Analytics-Komponente ist die Google Inc., 1600
                Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA. Der für
                die Verarbeitung Verantwortliche verwendet für die Web-Analyse
                über Google Analytics den Zusatz „_gat._anonymizeIp“. Mittels
                dieses Zusatzes wird die IP-Adresse des Internetanschlusses der
                betroffenen Person von Google gekürzt und anonymisiert, wenn der
                Zugriff auf unsere Internetseiten aus einem Mitgliedstaat der
                Europäischen Union oder aus einem anderen Vertragsstaat des
                Abkommens über den Europäischen Wirtschaftsraum erfolgt. Der
                Zweck der Google-Analytics-Komponente ist die Analyse der
                Besucherströme auf unserer Internetseite. Google nutzt die
                gewonnenen Daten und Informationen unter anderem dazu, die
                Nutzung unserer Internetseite auszuwerten, um für uns
                Online-Reports, welche die Aktivitäten auf unseren
                Internetseiten aufzeigen, zusammenzustellen, und um weitere mit
                der Nutzung unserer Internetseite in Verbindung stehende
                Dienstleistungen zu erbringen. Google Analytics setzt ein Cookie
                auf dem informationstechnologischen System der betroffenen
                Person. Was Cookies sind, wurde oben bereits erläutert. Mit
                Setzung des Cookies wird Google eine Analyse der Benutzung
                unserer Internetseite ermöglicht. Durch jeden Aufruf einer der
                Einzelseiten dieser Internetseite, die durch den für die
                Verarbeitung Verantwortlichen betrieben wird und auf welcher
                eine Google-Analytics-Komponente integriert wurde, wird der
                Internetbrowser auf dem informationstechnologischen System der
                betroffenen Person automatisch durch die jeweilige
                Google-Analytics-Komponente veranlasst, Daten zum Zwecke der
                Online-Analyse an Google zu übermitteln. Im Rahmen dieses
                technischen Verfahrens erhält Google Kenntnis über
                personenbezogene Daten, wie der IP-Adresse der betroffenen
                Person, die Google unter anderem dazu dienen, die Herkunft der
                Besucher und Klicks nachzuvollziehen und in der Folge
                Provisionsabrechnungen zu ermöglichen. Mittels des Cookies
                werden personenbezogene Informationen, beispielsweise die
                Zugriffszeit, der Ort, von welchem ein Zugriff ausging und die
                Häufigkeit der Besuche unserer Internetseite durch die
                betroffene Person, gespeichert. Bei jedem Besuch unserer
                Internetseiten werden diese personenbezogenen Daten,
                einschließlich der IP-Adresse des von der betroffenen Person
                genutzten Internetanschlusses, an Google in den Vereinigten
                Staaten von Amerika übertragen. Diese personenbezogenen Daten
                werden durch Google in den Vereinigten Staaten von Amerika
                gespeichert. Google gibt diese über das technische Verfahren
                erhobenen personenbezogenen Daten unter Umständen an Dritte
                weiter. Die betroffene Person kann die Setzung von Cookies durch
                unsere Internetseite, wie oben bereits dargestellt, jederzeit
                mittels einer entsprechenden Einstellung des genutzten
                Internetbrowsers verhindern und damit der Setzung von Cookies
                dauerhaft widersprechen. Eine solche Einstellung des genutzten
                Internetbrowsers würde auch verhindern, dass Google ein Cookie
                auf dem informationstechnologischen System der betroffenen
                Person setzt. Zudem kann ein von Google Analytics bereits
                gesetzter Cookie jederzeit über den Internetbrowser oder andere
                Softwareprogramme gelöscht werden. Ferner besteht für die
                betroffene Person die Möglichkeit, einer Erfassung der durch
                Google Analytics erzeugten, auf eine Nutzung dieser
                Internetseite bezogenen Daten sowie der Verarbeitung dieser
                Daten durch Google zu widersprechen und eine solche zu
                verhindern. Hierzu muss die betroffene Person ein Browser-Add-On
                unter dem Link https://tools.google.com/dlpage/gaoptout
                herunterladen und installieren. Dieses Browser-Add-On teilt
                Google Analytics über JavaScript mit, dass keine Daten und
                Informationen zu den Besuchen von Internetseiten an Google
                Analytics übermittelt werden dürfen. Die Installation des
                Browser-Add-Ons wird von Google als Widerspruch gewertet. Wird
                das informationstechnologische System der betroffenen Person zu
                einem späteren Zeitpunkt gelöscht, formatiert oder neu
                installiert, muss durch die betroffene Person eine erneute
                Installation des Browser-Add-Ons erfolgen, um Google Analytics
                zu deaktivieren. Sofern das Browser-Add-On durch die betroffene
                Person oder einer anderen Person, die ihrem Machtbereich
                zuzurechnen ist, deinstalliert oder deaktiviert wird, besteht
                die Möglichkeit der Neuinstallation oder der erneuten
                Aktivierung des Browser-Add-Ons. Weitere Informationen und die
                geltenden Datenschutzbestimmungen von Google können unter
                https://www.google.de/intl/de/policies/privacy/ und unter
                http://www.google.com/analytics/terms/de.html abgerufen werden.
                Google Analytics wird unter diesem Link
                https://www.google.com/intl/de_de/analytics/ genauer erläutert.
                {/* Section 11*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    11. Datenschutzbestimmungen zu Einsatz und Verwendung von
                    Google-AdWords
                  </h1>
                  <br />
                </div>
                Der für die Verarbeitung Verantwortliche hat auf dieser
                Internetseite Google AdWords integriert. Google AdWords ist ein
                Dienst zur Internetwerbung, der es Werbetreibenden gestattet,
                sowohl Anzeigen in den Suchmaschinenergebnissen von Google als
                auch im Google-Werbenetzwerk zu schalten. Google AdWords
                ermöglicht es einem Werbetreibenden, vorab bestimmte
                Schlüsselwörter festzulegen, mittels derer eine Anzeige in den
                Suchmaschinenergebnissen von Google ausschließlich dann
                angezeigt wird, wenn der Nutzer mit der Suchmaschine ein
                schlüsselwortrelevantes Suchergebnis abruft. Im
                Google-Werbenetzwerk werden die Anzeigen mittels eines
                automatischen Algorithmus und unter Beachtung der zuvor
                festgelegten Schlüsselwörter auf themenrelevanten Internetseiten
                verteilt. Betreibergesellschaft der Dienste von Google AdWords
                ist die Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA
                94043-1351, USA. Der Zweck von Google AdWords ist die Bewerbung
                unserer Internetseite durch die Einblendung von
                interessenrelevanter Werbung auf den Internetseiten von
                Drittunternehmen und in den Suchmaschinenergebnissen der
                Suchmaschine Google und eine Einblendung von Fremdwerbung auf
                unserer Internetseite. Gelangt eine betroffene Person über eine
                Google-Anzeige auf unsere Internetseite, wird auf dem
                informationstechnologischen System der betroffenen Person durch
                Google ein sogenannter Conversion-Cookie abgelegt. Was Cookies
                sind, wurde oben bereits erläutert. Ein Conversion-Cookie
                verliert nach dreißig Tagen seine Gültigkeit und dient nicht zur
                Identifikation der betroffenen Person. Über den
                Conversion-Cookie wird, sofern das Cookie noch nicht abgelaufen
                ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise der
                Warenkorb von einem Online-Shop-System, auf unserer
                Internetseite aufgerufen wurden. Durch den Conversion-Cookie
                können sowohl wir als auch Google nachvollziehen, ob eine
                betroffene Person, die über eine AdWords-Anzeige auf unsere
                Internetseite gelangt ist, einen Umsatz generierte, also einen
                Warenkauf vollzogen oder abgebrochen hat. Die durch die Nutzung
                des Conversion-Cookies erhobenen Daten und Informationen werden
                von Google verwendet, um Besuchsstatistiken für unsere
                Internetseite zu erstellen. Diese Besuchsstatistiken werden
                durch uns wiederum genutzt, um die Gesamtanzahl der Nutzer zu
                ermitteln, welche über AdWords-Anzeigen an uns vermittelt
                wurden, also um den Erfolg oder Misserfolg der jeweiligen
                AdWords-Anzeige zu ermitteln und um unsere AdWords-Anzeigen für
                die Zukunft zu optimieren. Weder unser Unternehmen noch andere
                Werbekunden von Google-AdWords erhalten Informationen von
                Google, mittels derer die betroffene Person identifiziert werden
                könnte. Mittels des Conversion-Cookies werden personenbezogene
                Informationen, beispielsweise die durch die betroffene Person
                besuchten Internetseiten, gespeichert. Bei jedem Besuch unserer
                Internetseiten werden demnach personenbezogene Daten,
                einschließlich der IP-Adresse des von der betroffenen Person
                genutzten Internetanschlusses, an Google in den Vereinigten
                Staaten von Amerika übertragen. Diese personenbezogenen Daten
                werden durch Google in den Vereinigten Staaten von Amerika
                gespeichert. Google gibt diese über das technische Verfahren
                erhobenen personenbezogenen Daten unter Umständen an Dritte
                weiter. Die betroffene Person kann die Setzung von Cookies durch
                unsere Internetseite, wie oben bereits dargestellt, jederzeit
                mittels einer entsprechenden Einstellung des genutzten
                Internetbrowsers verhindern und damit der Setzung von Cookies
                dauerhaft widersprechen. Eine solche Einstellung des genutzten
                Internetbrowsers würde auch verhindern, dass Google einen
                Conversion-Cookie auf dem informationstechnologischen System der
                betroffenen Person setzt. Zudem kann ein von Google AdWords
                bereits gesetzter Cookie jederzeit über den Internetbrowser oder
                andere Softwareprogramme gelöscht werden. Ferner besteht für die
                betroffene Person die Möglichkeit, der interessenbezogenen
                Werbung durch Google zu widersprechen. Hierzu muss die
                betroffene Person von jedem der von ihr genutzten
                Internetbrowser aus den Link www.google.de/settings/ads aufrufen
                und dort die gewünschten Einstellungen vornehmen. Weitere
                Informationen und die geltenden Datenschutzbestimmungen von
                Google können unter
                https://www.google.de/intl/de/policies/privacy/ abgerufen
                werden.
                {/* Section 12*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    12. Datenschutzbestimmungen zu Einsatz und Verwendung von
                    Instagram
                  </h1>
                  <br />
                </div>
                Der für die Verarbeitung Verantwortliche hat auf dieser
                Internetseite Komponenten des Dienstes Instagram integriert.
                Instagram ist ein Dienst, der als audiovisuelle Plattform zu
                qualifizieren ist und den Nutzern das Teilen von Fotos und
                Videos und zudem eine Weiterverbreitung solcher Daten in anderen
                sozialen Netzwerken ermöglicht. Betreibergesellschaft der
                Dienste von Instagram ist die Instagram LLC, 1 Hacker Way,
                Building 14 First Floor, Menlo Park, CA, USA. Durch jeden Aufruf
                einer der Einzelseiten dieser Internetseite, die durch den für
                die Verarbeitung Verantwortlichen betrieben wird und auf welcher
                eine Instagram-Komponente (Insta-Button) integriert wurde, wird
                der Internetbrowser auf dem informationstechnologischen System
                der betroffenen Person automatisch durch die jeweilige
                Instagram-Komponente veranlasst, eine Darstellung der
                entsprechenden Komponente von Instagram herunterzuladen. Im
                Rahmen dieses technischen Verfahrens erhält Instagram Kenntnis
                darüber, welche konkrete Unterseite unserer Internetseite durch
                die betroffene Person besucht wird. Sofern die betroffene Person
                gleichzeitig bei Instagram eingeloggt ist, erkennt Instagram mit
                jedem Aufruf unserer Internetseite durch die betroffene Person
                und während der gesamten Dauer des jeweiligen Aufenthaltes auf
                unserer Internetseite, welche konkrete Unterseite die betroffene
                Person besucht. Diese Informationen werden durch die
                Instagram-Komponente gesammelt und durch Instagram dem
                jeweiligen Instagram-Account der betroffenen Person zugeordnet.
                Betätigt die betroffene Person einen der auf unserer
                Internetseite integrierten Instagram-Buttons, werden die damit
                übertragenen Daten und Informationen dem persönlichen
                Instagram-Benutzerkonto der betroffenen Person zugeordnet und
                von Instagram gespeichert und verarbeitet. Instagram erhält über
                die Instagram-Komponente immer dann eine Information darüber,
                dass die betroffene Person unsere Internetseite besucht hat,
                wenn die betroffene Person zum Zeitpunkt des Aufrufs unserer
                Internetseite gleichzeitig bei Instagram eingeloggt ist; dies
                findet unabhängig davon statt, ob die betroffene Person die
                Instagram-Komponente anklickt oder nicht. Ist eine derartige
                Übermittlung dieser Informationen an Instagram von der
                betroffenen Person nicht gewollt, kann diese die Übermittlung
                dadurch verhindern, dass sie sich vor einem Aufruf unserer
                Internetseite aus ihrem Instagram-Account ausloggt. Weitere
                Informationen und die geltenden Datenschutzbestimmungen von
                Instagram können unter
                https://help.instagram.com/155833707900388 und
                https://www.instagram.com/about/legal/privacy/ abgerufen werden.
                {/* Section 13*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    13. Datenschutzbestimmungen zu Einsatz und Verwendung von
                    Twitter
                  </h1>
                  <br />
                </div>
                Der für die Verarbeitung Verantwortliche hat auf dieser
                Internetseite Komponenten von Twitter integriert. Twitter ist
                ein multilingualer öffentlich zugänglicher Mikroblogging-Dienst,
                auf welchem die Nutzer sogenannte Tweets, also Kurznachrichten,
                die auf 140 Zeichen begrenzt sind, veröffentlichen und
                verbreiten können. Diese Kurznachrichten sind für jedermann,
                also auch für nicht bei Twitter angemeldete Personen abrufbar.
                Die Tweets werden aber auch den sogenannten Followern des
                jeweiligen Nutzers angezeigt. Follower sind andere
                Twitter-Nutzer, die den Tweets eines Nutzers folgen. Ferner
                ermöglicht Twitter über Hashtags, Verlinkungen oder Retweets die
                Ansprache eines breiten Publikums. Betreibergesellschaft von
                Twitter ist die Twitter, Inc., 1355 Market Street, Suite 900,
                San Francisco, CA 94103, USA. Durch jeden Aufruf einer der
                Einzelseiten dieser Internetseite, die durch den für die
                Verarbeitung Verantwortlichen betrieben wird und auf welcher
                eine Twitter-Komponente (Twitter-Button) integriert wurde, wird
                der Internetbrowser auf dem informationstechnologischen System
                der betroffenen Person automatisch durch die jeweilige
                Twitter-Komponente veranlasst, eine Darstellung der
                entsprechenden Twitter-Komponente von Twitter herunterzuladen.
                Weitere Informationen zu den Twitter-Buttons sind unter
                https://about.twitter.com/de/resources/buttons abrufbar. Im
                Rahmen dieses technischen Verfahrens erhält Twitter Kenntnis
                darüber, welche konkrete Unterseite unserer Internetseite durch
                die betroffene Person besucht wird. Zweck der Integration der
                Twitter-Komponente ist es, unseren Nutzern eine
                Weiterverbreitung der Inhalte diese Internetseite zu
                ermöglichen, diese Internetseite in der digitalen Welt bekannt
                zu machen und unsere Besucherzahlen zu erhöhen. Sofern die
                betroffene Person gleichzeitig bei Twitter eingeloggt ist,
                erkennt Twitter mit jedem Aufruf unserer Internetseite durch die
                betroffene Person und während der gesamten Dauer des jeweiligen
                Aufenthaltes auf unserer Internetseite, welche konkrete
                Unterseite unserer Internetseite die betroffene Person besucht.
                Diese Informationen werden durch die Twitter-Komponente
                gesammelt und durch Twitter dem jeweiligen Twitter-Account der
                betroffenen Person zugeordnet. Betätigt die betroffene Person
                einen der auf unserer Internetseite integrierten
                Twitter-Buttons, werden die damit übertragenen Daten und
                Informationen dem persönlichen Twitter-Benutzerkonto der
                betroffenen Person zugeordnet und von Twitter gespeichert und
                verarbeitet. Twitter erhält über die Twitter-Komponente immer
                dann eine Information darüber, dass die betroffene Person unsere
                Internetseite besucht hat, wenn die betroffene Person zum
                Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei
                Twitter eingeloggt ist; dies findet unabhängig davon statt, ob
                die betroffene Person die Twitter-Komponente anklickt oder
                nicht. Ist eine derartige Übermittlung dieser Informationen an
                Twitter von der betroffenen Person nicht gewollt, kann diese die
                Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf
                unserer Internetseite aus ihrem Twitter-Account ausloggt. Die
                geltenden Datenschutzbestimmungen von Twitter sind unter
                https://twitter.com/privacy?lang=de abrufbar.
                {/* Section 14*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>14. Rechtsgrundlage der Verarbeitung</h1>
                  <br />
                </div>
                Art. 6 I lit. a DS-GVO dient unserem Unternehmen als
                Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine
                Einwilligung für einen bestimmten Verarbeitungszweck einholen.
                Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines
                Vertrags, dessen Vertragspartei die betroffene Person ist,
                erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen
                der Fall ist, die für eine Lieferung von Waren oder die
                Erbringung einer sonstigen Leistung oder Gegenleistung notwendig
                sind, so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO.
                Gleiches gilt für solche Verarbeitungsvorgänge die zur
                Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa
                in Fällen von Anfragen zur unseren Produkten oder Leistungen.
                Unterliegt unser Unternehmen einer rechtlichen Verpflichtung
                durch welche eine Verarbeitung von personenbezogenen Daten
                erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher
                Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c
                DS-GVO. In seltenen Fällen könnte die Verarbeitung von
                personenbezogenen Daten erforderlich werden, um lebenswichtige
                Interessen der betroffenen Person oder einer anderen natürlichen
                Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein
                Besucher in unserem Betrieb verletzt werden würde und daraufhin
                sein Name, sein Alter, seine Krankenkassendaten oder sonstige
                lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder
                sonstige Dritte weitergegeben werden müssten. Dann würde die
                Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen. Letztlich
                könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO
                beruhen. Auf dieser Rechtsgrundlage basieren
                Verarbeitungsvorgänge, die von keiner der vorgenannten
                Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur
                Wahrung eines berechtigten Interesses unseres Unternehmens oder
                eines Dritten erforderlich ist, sofern die Interessen,
                Grundrechte und Grundfreiheiten des Betroffenen nicht
                überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere
                deshalb gestattet, weil sie durch den Europäischen Gesetzgeber
                besonders erwähnt wurden. Er vertrat insoweit die Auffassung,
                dass ein berechtigtes Interesse anzunehmen sein könnte, wenn die
                betroffene Person ein Kunde des Verantwortlichen ist
                (Erwägungsgrund 47 Satz 2 DS-GVO).
                {/* Section 15*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    15. Berechtigte Interessen an der Verarbeitung, die von dem
                    Verantwortlichen oder einem Dritten verfolgt werden
                  </h1>
                  <br />
                </div>
                Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I
                lit. f DS-GVO ist unser berechtigtes Interesse die Durchführung
                unserer Geschäftstätigkeit zugunsten des Wohlergehens all
                unserer Mitarbeiter und unserer Anteilseigner.
                {/* Section 16*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    16. Dauer, für die die personenbezogenen Daten gespeichert
                    werden
                  </h1>
                  <br />
                </div>
                Das Kriterium für die Dauer der Speicherung von
                personenbezogenen Daten ist die jeweilige gesetzliche
                Aufbewahrungsfrist. Nach Ablauf der Frist werden die
                entsprechenden Daten routinemäßig gelöscht, sofern sie nicht
                mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich
                sind.
                {/* Section 17*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    17. Gesetzliche oder vertragliche Vorschriften zur
                    Bereitstellung der personenbezogenen Daten; Erforderlichkeit
                    für den Vertragsabschluss; Verpflichtung der betroffenen
                    Person, die personenbezogenen Daten bereitzustellen;
                    mögliche Folgen der Nichtbereitstellung
                  </h1>
                  <br />
                </div>
                Wir klären Sie darüber auf, dass die Bereitstellung
                personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist
                (z.B. Steuervorschriften) oder sich auch aus vertraglichen
                Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann.
                Mitunter kann es zu einem Vertragsschluss erforderlich sein,
                dass eine betroffene Person uns personenbezogene Daten zur
                Verfügung stellt, die in der Folge durch uns verarbeitet werden
                müssen. Die betroffene Person ist beispielsweise verpflichtet
                uns personenbezogene Daten bereitzustellen, wenn unser
                Unternehmen mit ihr einen Vertrag abschließt. Eine
                Nichtbereitstellung der personenbezogenen Daten hätte zur Folge,
                dass der Vertrag mit dem Betroffenen nicht geschlossen werden
                könnte. Vor einer Bereitstellung personenbezogener Daten durch
                den Betroffenen muss sich der Betroffene an einen unserer
                Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen
                einzelfallbezogen darüber auf, ob die Bereitstellung der
                personenbezogenen Daten gesetzlich oder vertraglich
                vorgeschrieben oder für den Vertragsabschluss erforderlich ist,
                ob eine Verpflichtung besteht, die personenbezogenen Daten
                bereitzustellen, und welche Folgen die Nichtbereitstellung der
                personenbezogenen Daten hätte.
                {/* Section 18*/}
                <div class="cb-subheader">
                  <br /> <br />
                  <h1>
                    18. Bestehen einer automatisierten Entscheidungsfindung
                  </h1>
                  <br />
                </div>
                Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
                automatische Entscheidungsfindung oder ein Profiling.
              </p>
            </div>
        
      
      </div>
      <Footer />
    </>
  )
}
